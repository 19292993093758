import BULMA_CONSTANTS from "react-bulma-components/lib/constants";

export const { BREAKPOINTS, COLORS } = BULMA_CONSTANTS;

export const STAT_SIZE = 5;
export const JWT_TOKEN_NAME = "JWT";
export const JWT_REFRESH_TOKEN_NAME = "JWT-refresh-token";

export const LOCAL_STORAGE_USER = "user";
export const LOCAL_STORAGE_TOKEN = "token";
export const LOCAL_STORAGE_REFRESH_EXPIRES = "refreshExpiresIn";
export const LOCAL_STORAGE_REFRESH_TOKEN = "refreshToken";
export const JWT_AUTH_HEADER = "Authorization";

export const TOKEN_NAME = "__session";

export const SIGNIN_URL = "/login";
export const DEFAULT_SIGNIN_REDIRECT = "/user/profile";
export const LOGOUT_EVENT = "logout";
export const STORAGE = "storage";
export const LOGOUT_KEY = "logout";

export const UNDEFINED = "undefined";
export const FUNCTION = "function";

export const IN_REVIEW = "IN_REVIEW";
export const DRAFT = "DRAFT";
export const PUBLISHED = "PUBLISHED";

export const colors = {
  Default: "",
  primary: "primary",
  info: "info",
  danger: "danger",
  warning: "warning",
  success: "success",
  white: "white",
  black: "black",
  light: "light",
  dark: "dark",
  link: "link"
};

export const positions = {
  default: "",
  centered: "centered",
  right: "right"
};

export const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY;
export const BACKEND = process.env.BACKEND;
export const FRONTEND = process.env.FRONTEND
  ? `https://${process.env.FRONTEND}`
  : "http://localhost:3000";
export const API_URL = `${BACKEND}/graphql/`;
export const PRE_SIGNED_POST_END_POINT = `${BACKEND}/signed-url/`;
export const GA_AVAILABLE = process.env.GA_KEY ? true : false;

export const LONG_PASSWORD_LENGTH = 8;
export const VERY_LONG_PASSWORD_LENGTH = 12;
export const ACCEPTABLE_PASSWORD_STRENGTH = 3;
export const GOOD_PASSWORD_STRENGTH = 5;
export const MAX_PASSWORD_STRENGTH = 6;

export const IMMEDIATELY = "IMMEDIATELY";
export const DAILY_DIGEST = "DAILY_DIGEST";
export const WEEKLY_DIGEST = "WEEKLY_DIGEST";

export const NEWSLETTER_FREQ_CHOICES = [
  IMMEDIATELY,
  DAILY_DIGEST,
  WEEKLY_DIGEST
];

export const GOOGLE_OAUTH_CLIENT_ID = process.env.GOOGLE_OAUTH_CLIENT_ID;

export const FIREBASE_APP_CONFIG = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.FIREBASE_DATABASEURL,
  projectId: process.env.FIREBASE_PROJECTID,
  storageBucket: process.env.FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
  appId: process.env.FIREBASE_APPID,
  measurementId: process.env.FIREBASE_MEASUREMENTID
};

export const IS_DEV = process.env.NODE_ENV == "development";

export const PROFILE_SECTIONS = {
  applications: {
    name: "Applications",
    key: ""
  },
  referrals: {
    name: "Referrals",
    key: "referrals"
  },
  jobs: {
    name: "Jobs",
    key: "jobs"
  },
  settings: {
    name: "Settings",
    key: "settings"
  }
};

export const APPLICATION_STATUS = {
  applied: {
    name: "Applied",
    key: "applied"
  },
  shortlisted: {
    name: "Shortlisted",
    key: "shortlisted"
  },
  rejected: {
    name: "Rejected",
    key: "rejected"
  },
  selected: {
    name: "Selected",
    key: "selected"
  }
};

export const JOBS_PAGE_SIZE = 25;
