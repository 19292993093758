import { Fragment } from "react";
import {
  Section,
  Container,
  Heading,
  Button,
  Columns,
  Media,
  Image,
  Content,
  Level
} from "react-bulma-components";
import { COLORS } from "../constants";
import Icon from "../components/Icon";
import Link from "next/link";

const HomePage = () => {
  return (
    <Fragment>
      <Section size="medium" className="home-cover">
        <Container>
          <Columns>
            <Columns.Column size="half">
              <Heading size={2}>
                <span className="has-text-primary">Refer friends</span> &amp;
                earn bounty
              </Heading>
              <Heading subtitle>
                Companies reward successful referrals to their jobs on
                BountyHiring
              </Heading>
              <div>
                {/* <Heading subtitle size={4} renderAs="h2"> */}
                <Link href="/calculator/how-much-can-earn" passHref>
                  <a>
                    Calculate your network value{" "}
                    <Icon icon="arrow-circle-right" />
                  </a>
                </Link>
                {/* </Heading> */}
                <br />
                <br />
                <Button.Group size="large">
                  <Link href="/jobs" passHref>
                    <a
                      color={COLORS.PRIMARY}
                      size="large"
                      className="button is-primary"
                    >
                      View jobs
                    </a>
                  </Link>
                  <Link href="/companies/admin/" passHref>
                    <a
                      color={COLORS.INFO}
                      size="large"
                      className="button is-info"
                    >
                      Post a job
                    </a>
                  </Link>
                </Button.Group>
              </div>
              <br />
              <br />
            </Columns.Column>
            <Columns.Column size="half">
              <div className="video-container">
                <iframe
                  width="560"
                  height="700"
                  src="https://www.youtube.com/embed/6Uf47MFrv9o?controls=0"
                  // frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  // allowfullscreen
                  controls="0"
                />
              </div>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
      <Section size="medium" className="how-works">
        <Container>
          <Heading size={2} textAlignment="centered" id="how">
            How it works
          </Heading>
          <br />
          <br />
          <Columns>
            <Columns.Column size="one-third" offset="one-third">
              <Media>
                <Media.Item renderAs="figure" position="left">
                  <Icon icon="search" size="large" faSize="3x" />
                </Media.Item>
                <Media.Item>
                  <Content>
                    <p>
                      <strong>Pick a job</strong>
                      <br />
                      Browse our jobs and find one to which you can refer
                      someone.&nbsp;
                      {/* <br /><br /> */}
                      <i>Pro-tip</i>: pick jobs from your own trade. If you're a
                      developer, look for developer jobs.
                    </p>
                  </Content>
                </Media.Item>
              </Media>
              <br />
              <Media>
                <Media.Item renderAs="figure" position="left">
                  <Icon icon="file-upload" size="large" faSize="3x" />
                </Media.Item>
                <Media.Item>
                  <Content>
                    <p>
                      <strong>Refer your talented friend/colleague</strong>
                      <br />
                      Fill in their contact details, your testimonial and attach
                      their resumé
                    </p>
                  </Content>
                </Media.Item>
              </Media>
              <br />
              <Media>
                <Media.Item renderAs="figure" position="left">
                  <Icon icon="trophy" size="large" faSize="3x" />
                </Media.Item>
                <Media.Item>
                  <Content>
                    <p>
                      <strong>Get paid if they get hired</strong>
                      <br />
                      If the company hires based on your referral and the
                      candidate joins them, we'll pay you the bounty.
                    </p>
                  </Content>
                </Media.Item>
              </Media>
              <br />
            </Columns.Column>
          </Columns>
          <br />
          <br />
          <Level>
            <Level.Item>
              <Link href="#why" passHref>
                <a
                  color={COLORS.INFO}
                  size="large"
                  className="button is-info is-large"
                >
                  <Icon icon="chevron-circle-down" size="small" />
                  Why it works
                </a>
              </Link>
            </Level.Item>
          </Level>
        </Container>
      </Section>
      <Section size="medium" className="why-works">
        <Container>
          <Heading size={2} textAlignment="centered" id="why">
            Why it works
          </Heading>
          <br />
          <br />
          <Columns>
            <Columns.Column size="half">
              <Heading>
                For <span className="has-text-primary">Companies</span>
              </Heading>
              <Media>
                <Media.Item renderAs="figure" position="left">
                  <Icon icon="funnel-dollar" size="large" faSize="3x" />
                </Media.Item>
                <Media.Item>
                  <Content>
                    <p>
                      <strong>Save on cost of hiring</strong>
                      <br />
                      People only vouch for great colleagues. Less noise and
                      lower costs.
                    </p>
                  </Content>
                </Media.Item>
              </Media>
              <br />
              <Media>
                <Media.Item renderAs="figure" position="left">
                  <Icon icon="globe-africa" size="large" faSize="3x" />
                </Media.Item>
                <Media.Item>
                  <Content>
                    <p>
                      <strong>Diverse by design</strong>
                      <br />
                      Internal referral programmes are optimised to bring in
                      more <u>"people like us"</u> leading to a monolithic
                      culture. BountyHiring uncovers the world's best talent
                      across diverse backgrounds.
                    </p>
                  </Content>
                </Media.Item>
              </Media>
              <br />
              <Media>
                <Media.Item renderAs="figure" position="left">
                  <Icon icon={["far", "star"]} faSize="3x" size="large" />
                </Media.Item>
                <Media.Item>
                  <Content>
                    <p>
                      <strong>Exposes the true upside of talent</strong>
                      <br />
                      The talent who push the boundaries of innovation are
                      extremely hard to find unless someone tells you about
                      them. One of the surprising reasons for that is they don't
                      know this themselves. BountyHiring exposes you to this
                      positive luck.
                    </p>
                  </Content>
                </Media.Item>
              </Media>
              <br />
            </Columns.Column>
            <Columns.Column size="half">
              <Heading>
                For <span className="has-text-primary">Talent</span>
              </Heading>
              <Media>
                <Media.Item renderAs="figure" position="left">
                  <Icon icon="feather" size="large" faSize="3x" />
                </Media.Item>
                <Media.Item>
                  <Content>
                    <p>
                      <strong>Spend time on your greatest work</strong>
                      <br />
                      Stop hustling to get noticed.
                      <br />
                      Imagine an assistant who finds you the best jobs on the
                      market 24/7? That's BountyHiring. We believe talent should
                      never have to worry about jobs.
                    </p>
                  </Content>
                </Media.Item>
              </Media>
              <br />
              <Media>
                <Media.Item renderAs="figure" position="left">
                  {/* <BulmaIcon size="large">
                    <FontAwesomeIcon icon={faThumbsUp} size="3x" />
                  </BulmaIcon> */}
                  <Icon icon={["far", "thumbs-up"]} faSize="3x" size="large" />
                </Media.Item>
                <Media.Item>
                  <Content>
                    <p>
                      <strong>
                        There <u>IS</u> a market for what you do
                      </strong>
                      <br />
                      Recall the last time someone shot you down with "there's
                      no market for that"? We can't make huge promises but we
                      designed BountyHiring for companies to find people with
                      unique stories.
                    </p>
                  </Content>
                </Media.Item>
              </Media>
              <br />
              <Media>
                <Media.Item renderAs="figure" position="left">
                  <Icon icon="chalkboard-teacher" size="large" faSize="3x" />
                </Media.Item>
                <Media.Item>
                  <Content>
                    <p>
                      <strong>Share your knowledge and get rewarded</strong>
                      <br />
                      Are you good at mentoring others? We reward you with a
                      higher referral quota which gives you upto <b>5x</b>
                      &nbsp;higher chance of getting paid.
                    </p>
                  </Content>
                </Media.Item>
              </Media>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
      <Section className="call-to-action">
        <Container>
          <Heading textAlignment="centered">Excited?</Heading>
          <Heading textAlignment="centered" subtitle>
            Get started by signing up
          </Heading>
          <center>
            <Link href="/login" passHref>
              <a
                size="large"
                className="button is-primary is-large is-outlined"
              >
                {/* <Icon icon="list" size="small" faSize="3x" /> */}
                <b>Sign Up</b>
              </a>
            </Link>
          </center>
          <br />
          <br />
          <center>
            More Questions? Check&nbsp;
            <a
              href="https://blog.bountyhiring.com/frequently-asked-questions/"
              target="_blank"
            >
              FAQ
            </a>{" "}
            or contact{" "}
            <Link href="/support" passHref>
              <a>Support</a>
            </Link>
          </center>
        </Container>
      </Section>
    </Fragment>
  );
};

export default HomePage;
